<template>
	<div class="hx-order-four">

		<div align="left" class="left">
			<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px"
				style="vertical-align: middle;">
			<div align="left">
				<h3>上传X光片</h3>
				<span style="font-size: 0.9375rem;line-height: 1.875rem;">请上传一个口腔全景片,如果有的话,再上传一个头颅侧位片。</span>
				<div  style="font-size: 0.9375rem;line-height: 1.875rem;">您可以在<a href="./xguang.pdf" target="_blank"
						style="color: #0D8EA4;font-weight: bold;text-decoration: underline;">此处</a>找到有关图片的示例。</div>
				<div>
					<el-form :model="ruleForm" ref="ruleForm">
						<el-form-item prop="quanjingDate" label="口腔全景片">
							<el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.quanjingDate"
								style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>
						</el-form-item>
						<el-form-item prop="ceweiDate" label="头颅侧位片">
							<el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.ceweiDate"
								style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>
						</el-form-item>
					</el-form>
				</div>
				<div style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">注意</span>：图像需近30天内</div>
				<div style="word-break:break-all; font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">有效格式</span>：.png、.jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff</div>
				<div style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bolder;">最小文件大小</span>：100KB</div>
			</div>

		</div>



	<div class="right">
		<!-- 全景 -->
		<div style="position: relative;left: -14.95rem;">口腔全景片</div>
		<el-upload style="margin-bottom: 30px;" class="upload-demo" drag action='' ref="upload" :http-request="changeFile" multiple>
			<img v-show="qjVisiable" class="qj" :src="qjPicUrl" style="width: 100%; height: 100%;" />
			<div v-show="quanjingVisiable" style="width: 100%; height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;" >{{quanjingMess}}</div>
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
			<div class="el-upload__tip" slot="tip"></div>
		</el-upload>
		<!-- 请上传头颅侧位片 -->
		<div style="position: relative;left: -14.95rem;">头颅侧位片</div>
		<el-upload style="margin-bottom: 30px;" class="upload-demo1" drag action='' multiple :http-request="changeFile1">
			<img v-show="cwVisiable" class="cw":src="cwPicUrl" style="width: 100%; height: 100%;" />
			<div v-show="touluVisiable" style="width: 100%; height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;" >{{touluMess}}</div>
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
			<div class="el-upload__tip" slot="tip"></div>
		</el-upload>
		<div align="left" style="margin-bottom: 10px;color:red ;">
			注：如需传输CT文件，可联系我厂质控部负责人——何黎黎13256117868。
		</div>
		<div style="text-align: right;margin-top: 10px;">
			<el-button @click="back" class="back" type="button">返回</el-button>
			<el-button @click="nextStepKS" class="nextStep" type="button">下一步</el-button>
		</div>
		
	</div>
	
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				quanjingVisiable:false,
				touluVisiable:false,
				quanjingMess:'',
				touluMess:'',
				qjUpload:false,
				tlUpload:false,
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				ruleForm: {
					quanjingDate: '',
					ceweiDate: '',
					oid: '0'
				},
				qjPicUrl:'',
				cwPicUrl:'',
				cwVisiable:false,
				qjVisiable:false
			}
		},
		methods: {
			// beforeAvatarUpload(file) {
			// 	console.log('file', file)
			// 	let types = ['image/jpeg', 'image/jpg', 'image/png'];
			// 	const isImage = types.includes(file.type);
			// 	if (!isImage) {
			// 		this.$message.error('上传图片只能是 .jpg、.jpeg、.dcm(DICOM)、.bmp、.tif、.tiff 格式!');
			// 	} else {
			// 		//this.uploadToken.key=`upload_pic_${Date.parse(new Date())}${file.name}`
			// 	}
			// 	const isLtSize = file.size / 1024 / 1024 < 5;
			// 	if (!isLtSize) {
			// 		this.$message.error('上传图片大小不能超过 1MB!');
			// 	}
			// },
			//上传全景图
			changeFile(file) {
				if(this.tlUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.qjUpload=true;
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'quanjing')
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				/* this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						
						if (res.data.code == 200) {
							this.$message.success("上传成功");
							this.qjVisiable=true;
							//上传成功,图片回显
							this.qjPicUrl=res.data.data.picUrl;
						}
					}).catch(err => {
						this.$alert("系统出现异常,请联系管理员!");
					}); */
					this.quanjingVisiable=false;
					
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.quanjingMess="文件已上传至服务器,资源解析中......";
																 		this.quanjingVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
										console.log(res.data);
					                    if(res.data.code==200){
											this.qjUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											 let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 //this.quanjingMess="资源上传解析完毕："+fName;
											 this.quanjingMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.qjVisiable=true;
												 this.qjPicUrl=res.data.data.picUrl;
											 }else{
												  this.qjPicUrl="";
												  this.qjVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//侧位图
			changeFile1(file) {
				if(this.qjUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.tlUpload=true;
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'cewei')
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				/* this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						
						if (res.data.code == 200) {
							this.$message.success("上传成功");
							this.cwVisiable=true;
							//上传成功,图片回显
							this.cwPicUrl=res.data.data.picUrl;
						}
					}).catch(err => {
						this.$alert("系统出现异常,请联系管理员!");
					}); */
					this.touluVisiable=false;
					
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.touluMess="文件已上传至服务器,资源解析中......";
																 		this.touluVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
										console.log(res.data);
					                    if(res.data.code==200){
											this.tlUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.touluMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.cwVisiable=true;
												 this.cwPicUrl=res.data.data.picUrl;
											 }else{
												  this.cwPicUrl="";
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//下一步
			nextStepKS() {
				if(((this.qjPicUrl==""||this.qjPicUrl==null)&&(this.ruleForm.quanjingDate!=null&&this.ruleForm.quanjingDate!=''))){
					this.$alert("请上传全景图片","提示");
					return;
				}
				if(((this.qjPicUrl!=null&&this.qjPicUrl!="")&&(this.ruleForm.quanjingDate==null||this.ruleForm.quanjingDate==''))){
					this.$alert("请填写全景片成像日期","提示");
					return;
				}
				
				
				if((this.cwPicUrl==""||this.cwPicUrl==null)&&(this.ruleForm.ceweiDate!=""&&this.ruleForm.ceweiDate!=null)){
					this.$alert("请上传侧位图片","提示");
					return;
				}
				 
				if((this.cwPicUrl!=""&&this.cwPicUrl!=null)&&(this.ruleForm.ceweiDate==""||this.ruleForm.ceweiDate==null)){
					this.$alert("请填写侧位片上传日期","提示");
					return;
				}
				//1.上传全景日期
				 if(this.qjPicUrl!=null&&this.qjPicUrl!=''&&this.ruleForm.quanjingDate!=""&&this.ruleForm.quanjingDate!=null){
				//上传两个日期
				let result1;
				let formData1 = new FormData()
				formData1.append('date', this.ruleForm.quanjingDate) // 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type', 'qjDate')

				this.$axios.post("client/order/uploadResourceDate", formData1, {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					result1 = res.data.code;
				}).catch(err => {
					this.$message.error("添加失败");
				});
				}
				// this.$alert(typeof(this.ruleForm.ceweiDate),"提示")
				//2.上传头颅侧位
				if((this.cwPicUrl!=""&&this.cwPicUrl!=null)&&(this.ruleForm.ceweiDate!=""&&this.ruleForm.ceweiDate!="null")){
				let result2;
				//头颅侧位
				let formData2 = new FormData()
				formData2.append('date', this.ruleForm.ceweiDate) // 传文件
				formData2.append('oid', this.ruleForm.oid)
				formData2.append('type', 'cwDate')

				this.$axios.post("client/order/uploadResourceDate", formData2, {
					headers: {
						"Content-type": "application/json"
					}
				}).then(res => {
					result2 = res.data.code;
					
				}).catch(err => {
					this.$message.error("添加失败");
				});
				
				}
				this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderfour");
				this.$store.commit("setHxStepIndex",5);
				this.$router.push("/hxmain/hxorder/hxorderfive");
			},
			//返回
			back(){
				this.$store.commit("setHxStepIndex",3);
				this.$router.push("/hxmain/hxorder/hxorderthree");
			},

		},
		mounted: function() {
			this.$store.commit("setHxStepIndex",4);
			//设置默认地址
			sessionStorage.setItem("location", "/hxmain/hxorder/hxorderfour")
			//获取当前添加的订单oid
			//获取oid
			 let oid=sessionStorage.getItem("oid");
			 if(oid==null||oid=="null"||oid==""||oid==undefined){
			 	this.$alert("请填写患者信息","提示");
			 	this.$router.push("/hxmain/hxorder/hxorderone");
			 }else{
			 	this.ruleForm.oid=oid;
			 }
			//设置返回路径
			this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderthree");
		},
		created: function() {
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb show");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
			document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("oid");
			if (oid != "undefined" && oid != '' && oid != null) {
				this.ruleForm.oid = oid;
				this.$axios.get("/client/order/getOrderInfo", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					let qjDate = res.data.data.resource.qjDate;
					let cwDate = res.data.data.resource.cwDate;
					let cewei = res.data.data.resource.cewei;
					let quanjing = res.data.data.resource.quanjing;
					//全景日期回显
					if (qjDate != "null") {
						this.ruleForm.quanjingDate = qjDate;
					}
					//侧位日期回显
					// this.$alert(typeof(cwDate),"提示");
					if (cwDate != "null") {
						this.ruleForm.ceweiDate = cwDate;
					}
					//回显全景图片
					if (quanjing != null) {
						let fName=quanjing.substring(27,quanjing.length);
						var houZhui=quanjing.substring(quanjing.lastIndexOf('.')+1);
						if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																	this.qjVisiable=true;
																	this.qjPicUrl=quanjing;
																	document.getElementsByClassName("qj")[0].setAttribute("src",quanjing);
																	this.quanjingVisiable=true;
																	this.quanjingMess=fName;
						}else{
							this.quanjingVisiable=true;
							this.quanjingMess=fName;
								this.qjPicUrl="";
						}
						
					}
					//回显侧位图片
					if (cewei != null) {
						let fName=cewei.substring(27,cewei.length);
						var houZhui=cewei.substring(cewei.lastIndexOf('.')+1);
						if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																	this.cwVisiable=true;
																	this.cwPicUrl=cewei;
																	document.getElementsByClassName("cw")[0].setAttribute("src",cewei);
																	this.touluVisiable=true;
																	this.touluMess=fName;
						}else{
							this.touluVisiable=true;
							this.touluMess=fName;
								this.cwPicUrl="";
						}
						
					}
				}).catch(err => {
					this.$alert("系统出现异常,请联系管理员!");
				});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/hxmain/hxorder/hxorderone");
					}
				});
			}
			
		}
	}
</script>

<style scoped="scoped">
	.hx-order-four {
		width: 80%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		padding-bottom: 10px;
		justify-content: space-around;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}

	.left {
		
	/* 	display: flex;
		margin-right: 12.5rem;
		width: 31.25rem;
		position: relative;
		left: 12.5rem; */
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		/* position: relative;
		left: 4.15rem;
		 width: 35rem; */
		 width: 560px;
	}
	.hx-order-four .right .el-upload-dragger{
		width: 25rem;
	}

	/* 按钮 */
	.nextStep {
		background-color:#0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}
</style>
